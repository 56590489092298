export enum HEADER_TITLES {
  HOME = 'Home',
  TOURNAMENT = 'Tournament',
  GALLERY = 'Gallery',
  REGISTER = 'Register',
  CONTACT_US = 'Contact Us',
  ABOUT = 'About',
}

export enum TOURNAMENT_LIST {
  TOURNAMENT = '2025 Tournament',
  CUP_WINNERS = 'Cup Winners',
  TEAMS = 'Teams',
}
