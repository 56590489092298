import { Box, Button, Stack, alpha } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { CAROUSEL_PHOTOS } from '../../gallery/Gallery';
import { Theme } from '../../global-constants/Theme';
import AnimatedText from '../common/AnimatedText';
import { HEADER_HEIGHT } from '../header/Header';
import { CarouselComponent } from './CarouselComponent';
import { TSFNA_VALUES } from '../../gallery/tsfna-values/TSFNA_VALUES';
import AboutContainer from '../about/About';
import VisionMissionContainer from './VisionMissionContainer';

interface TournamentButtonProps {
  onClick: () => void;
}

const { innerWidth: width, innerHeight: height } = window;

const HomeContainer = () => {
  return (
    <Stack height="auto" width={width} style={{ marginTop: HEADER_HEIGHT }}>
      {/* <Confetti width={width} height={height} colors={['#FFC700', '#FC4100']} initialVelocityX={5} initialVelocityY={5} />; */}
      <FlyerContainer />
      <Box width="100%" display="flex" justifyContent="center" alignItems="center">
        <CarouselComponent photos={CAROUSEL_PHOTOS} />
      </Box>
      <VisionMissionContainer photos={TSFNA_VALUES} />
      <AboutContainer />
    </Stack>
  );
};

export default HomeContainer;

const TournamentButton = ({ onClick }: TournamentButtonProps) => {
  return (
    <Button
      variant="contained"
      sx={{
        width: 300,
        height: 40,
        borderRadius: 40,
        backgroundColor: alpha(Theme.orange, 0.8),
        ':hover': { backgroundColor: Theme.orange },
      }}
      onClick={onClick}
    >
      Tournament Schedule
    </Button>
  );
};

const FlyerContainer = () => {
  const navigate = useNavigate();

  return (
    <Box sx={{ width: '100%', height: height - HEADER_HEIGHT - 30, backgroundColor: 'white' }}>
      <Box
        component="img"
        src={require('../../gallery/min.png')}
        sx={{ width: width, height: height - HEADER_HEIGHT - 30, objectFit: 'fit' }}
      />
      <Stack
        direction="column"
        sx={{
          width: '100%',
          height: height - HEADER_HEIGHT - 40,
          backgroundColor: alpha('#000', 0.3),
          position: 'absolute',
          top: HEADER_HEIGHT,
        }}
      >
        <Stack
          direction="row"
          sx={{
            width: '100%',
            height: '90%',
          }}
        >
          <Box
            flexDirection="column"
            width={{ xs: '100%', sm: '100%', md: '100%', lg: '60%', xl: '60%' }}
            height="100%"
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'flex-start',
              padding: { xs: 2, sm: 2, md: 2, lg: 5, xl: 5 },
              textAlign: 'left',
            }}
          >
            <AnimatedText fontSize={width > 700 ? 50 : 23} text="TSFNA 2nd Annual Tournament" />
            <br />
            <br />
            <AnimatedText bg fontSize={width < 700 ? 14 : 20} text="Hosting state will be announced soon. Stay tuned!" />
          </Box>
          {/* <Box
            width={{ xs: '0%', sm: '0%', md: '0%', lg: '40%', xl: '40%' }}
            height="100%"
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Box component="img" src={require('../../gallery/flyer-2024.JPG')} sx={{ width: '60%', height: '60%', objectFit: 'fit' }} />
          </Box> */}
        </Stack>
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <TournamentButton onClick={() => navigate('tournament')} />
        </Box>
      </Stack>
    </Box>
  );
};
